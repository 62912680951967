<template>
  <router-view v-slot="{ Component }">
    <keep-alive :include="includeComponents">
      <component :is="Component" />
    </keep-alive>
  </router-view>
</template>

<script>
import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import dayjs from 'dayjs'
export default {
  name: 'App',
  setup() {
    const store = useStore()
    const includeComponents = computed(() => {
      return store.state.cache.keepLiveRoutes
    })

    onMounted(() => {
      // 退出网页时，统计登录时长
      document.addEventListener('visibilitychange', () => {
        console.log('刷新页面')
        if (document.visibilityState === 'hidden') {
          const formData = new FormData()
          formData.append('login_time', store.state.user.userInfo.loginTime)
          formData.append('leave_time', dayjs().format('YYYY-MM-DD HH:mm:ss'))
          formData.append('openid', store.state.user.userInfo.id)
          navigator.sendBeacon(
            `${process.env.VUE_APP_BURIED_API_URL}/user_point/create_user_visit_time/`,
            formData
          )
        }
      })
    })

    return {
      includeComponents
    }
  }
}
</script>

<style lang="less">
@import url('~@/styles/global.css');
@import url('~@/styles/iconfont.css');
@import url('~@/styles/ellipsis.less');

body {
  background: #eff3f2;
  font-family: Helvetica Neue, Helvetica, sans-serif !important;
}

.sticky {
  position: sticky;
  top: 0;
  z-index: 10;
}
</style>
