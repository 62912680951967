import * as axios from 'axios'
import { myStorage } from '@/utils/'

import { Toast } from 'vant'

class Server {
  constructor(options) {
    this.options = {
      baseURL: options.baseURL ? options.baseURL : process.env.VUE_APP_BATH_API_URL,
      timeout: options.timeout ? options.timeout : 30000,
      headers: {
        'Content-Type': 'application/json'
      },
      isLoading: true
    }
    this.instance = axios.create(this.options)
    this.setupInterceptors()
  }

  setupInterceptors() {
    // 请求拦截
    this.instance.interceptors.request.use(
      config => {
        // 设置tokens
        const token = myStorage.get('token')
        if (token) {
          config.headers.authorization = `Bearer ${token}`
        }
        if (config.isLoading) {
          Toast.loading({
            message: '加载中...',
            forbidClick: true,
            loadingType: 'spinner',
            duration: 0
          })
        }
        return config
      },
      error => {
        Toast.clear()
        Promise.reject(error)
      }
    )

    // 响应拦截
    this.instance.interceptors.response.use(
      res => {
        // console.log(res)
        Toast.clear()
        return res.data
      },
      err => {
        console.log(err)
        if (err.response.status === 401) {
          myStorage.clear()
          window.location.href = `${location.origin}`
        } else {
          Toast.clear()
          Toast({
            message: '请求错误',
            forbidClick: true,
            duration: 1000
          })
          Promise.reject(err)
        }
      }
    )
  }

  request(options) {
    if (options.method === 'post') {
      return this.instance.request(options)
    } else {
      return this.instance.request({
        ...options,
        params: { ...options.params, user_id: myStorage.get('userId') }
      })
    }
  }
}

export default Server
