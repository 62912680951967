import Server from '@/server/'
const server = new Server({
  baseURL: process.env.VUE_APP_BURIED_API_URL
})

// 获取 pathId
export const getPathId = params => {
  return server.request({
    url: '/user_point/get_path_id/',
    params,
    isLoading: false
  })
}

// 用户注册埋点
export const createUser = data => {
  return server.request({
    url: '/user_point/create_user/',
    method: 'post',
    data,
    isLoading: false
  })
}

// 用户路径埋点
export const userPath = data => {
  return server.request({
    url: '/user_point/user_path_check/',
    method: 'post',
    data,
    isLoading: false
  })
}

// 功能模块点击埋点
export const trackModule = data => {
  return server.request({
    url: '/user_point/create_function_check/',
    method: 'post',
    data,
    isLoading: false
  })
}

// 关键词搜索埋点
export const trackSearch = data => {
  return server.request({
    url: '/user_point/create_keyword_search/',
    method: 'post',
    data,
    isLoading: false
  })
}

// 肿瘤类型埋点
export const trackCancer = data => {
  return server.request({
    url: '/user_point/swelling_check/',
    method: 'post',
    data,
    isLoading: false
  })
}

// 统计报告埋点
export const trackReport = data => {
  return server.request({
    url: '/user_point/statistical_report_model_check/',
    method: 'post',
    data,
    isLoading: false
  })
}

// 访问量趋势埋点
export const trackVisit = data => {
  return server.request({
    url: '/user_point/visit_clinical_research/',
    method: 'post',
    data,
    isLoading: false
  })
}

// 临床研究5大模块点击统计
export const trackClinical = data => {
  return server.request({
    url: '/user_point/five_model_check/',
    method: 'post',
    data,
    isLoading: false
  })
}

// 临床研究筛选埋点
export const trackClinicalFilter = data => {
  return server.request({
    url: '/user_point/model_filter/',
    method: 'post',
    data,
    isLoading: false
  })
}

// 临床对比埋点
export const trackContrast = data => {
  return server.request({
    url: '/user_point/clinical_comparison_model_check/',
    method: 'post',
    data,
    isLoading: false
  })
}

// 临床对比文章埋点
export const trackContrastArticle = data => {
  return server.request({
    url: '/user_point/clinical_comparison_article/',
    method: 'post',
    data,
    isLoading: false
  })
}

// irae模块埋点
export const trackIraeModel = data => {
  return server.request({
    url: '/user_point/irAE_model_check/',
    method: 'post',
    data,
    isLoading: false
  })
}

// irae毒性埋点
export const trackIraeToxicity = data => {
  return server.request({
    url: '/user_point/toxicity_management/',
    method: 'post',
    data,
    isLoading: false
  })
}

// irae毒性埋点
export const trackMeeting = data => {
  return server.request({
    url: '/user_point/meeting_track/',
    method: 'post',
    data,
    isLoading: false
  })
}

// 文章点击
export const trackArticle = data => {
  return server.request({
    url: '/user_point/article_check/',
    method: 'post',
    data,
    isLoading: false
  })
}
