import { createRouter, createWebHashHistory } from 'vue-router'
import { iraeRoutes } from './irae'
import { getYmtUserId } from '@/api/'
import store from '@/store'
import myStorage from '@/utils/myStorage'
import { queryParams, getURLParameters } from '@/utils/'

const routes = [
  {
    path: '/',
    name: 'home',
    meta: { title: '首页' },
    component: () => import(/* webpackChunkName: "home" */ '../views/home/index.vue')
  },
  {
    path: '/clean',
    name: 'clean',
    meta: { title: '清理' },
    component: () => import(/* webpackChunkName: "home" */ '../views/clean/index.vue')
  },
  {
    path: '/robot',
    name: 'Robot',
    meta: { title: '' },
    component: () => import(/* webpackChunkName: "robot" */ '../views/robot/index.vue'),
    beforeEnter: to => {
      store.commit('cache/setKeep', 'Robot')
    }
  },
  {
    path: '/statement',
    name: 'Statement',
    meta: { title: '' },
    component: () => import(/* webpackChunkName: "home" */ '../views/statement/index.vue')
  },
  {
    path: '/my',
    name: 'My',
    meta: { title: '' },
    component: () => import(/* webpackChunkName: "home" */ '../views/my/index.vue')
  },
  {
    path: '/clinical',
    name: 'clinical',
    meta: { title: '临床研究' },
    component: () => import(/* webpackChunkName: "clinical" */ '../views/clinical/index.vue')
  },
  {
    path: '/timeline',
    name: 'TimeLine',
    meta: { title: '里程碑' },
    component: () => import(/* webpackChunkName: "clinical" */ '../views/clinical/timeline.vue'),
    beforeEnter: to => {
      store.commit('cache/setKeep', 'Timeline')
    }
  },
  {
    path: '/report',
    name: 'Report',
    meta: { title: '统计报告' },
    component: () => import(/* webpackChunkName: "clinical" */ '../views/clinical/report.vue'),
    beforeEnter: to => {
      store.commit('cache/setKeep', 'Report')
    }
  },
  {
    path: '/clinicalList',
    name: 'ClinicalList',
    meta: { title: '临床汇总' },
    component: () => import(/* webpackChunkName: "clinical" */ '../views/clinical/clinicalList/'),
    beforeEnter: to => {
      store.commit('cache/setKeep', 'ClinicalList')
    }
  },
  {
    path: '/clinicalSearch',
    name: 'ClinicalSearch',
    meta: { title: '' },
    component: () => import(/* webpackChunkName: "clinical" */ '../views/clinical/clinicalSearch/')
  },
  {
    path: '/contrast',
    name: 'Contrast',
    meta: { title: '临床对比' },
    component: () => import(/* webpackChunkName: "clinical" */ '../views/clinical/contrast/'),
    beforeEnter: to => {
      store.commit('cache/setKeep', 'Contrast')
    }
  },
  {
    path: '/clinicalDetail',
    name: 'ClinicalDetail',
    meta: { title: '' },
    component: () => import(/* webpackChunkName: "clinical" */ '../views/clinical/clinicalDetail/')
  },
  {
    path: '/contrastDetail',
    name: 'ContrastDetail',
    meta: { title: '' },
    component: () => import(/* webpackChunkName: "clinical" */ '../views/clinical/contrastDetail/')
  },
  {
    path: '/landscape',
    name: 'Landscape',
    meta: { title: '' },
    component: () =>
      import(/* webpackChunkName: "clinical" */ '../views/clinical/contrastDetail/landscape')
  },
  {
    path: '/cancer',
    name: 'Cancer',
    meta: { title: '人体图' },
    component: () => import(/* webpackChunkName: "human" */ '../views/human/index.vue')
  },
  {
    path: '/conference',
    name: 'Conference',
    meta: { title: '会议追踪' },
    component: () => import(/* webpackChunkName: "conference" */ '../views/meeting/index.vue'),
    beforeEnter: to => {
      store.commit('cache/setKeep', 'Conference')
    }
  },
  {
    path: '/conference/list',
    name: 'ConferenceList',
    meta: { title: '' },
    component: () => import(/* webpackChunkName: "conference" */ '../views/meeting/list.vue'),
    beforeEnter: to => {
      store.commit('cache/setKeep', 'MonthList')
    }
  },
  {
    path: '/conference/announcement',
    name: 'ConferenceAnnouncement',
    meta: { title: '' },
    component: () =>
      import(/* webpackChunkName: "conference" */ '../views/meeting/announcement/index.vue'),
    beforeEnter: to => {
      store.commit('cache/setKeep', 'Announcement')
    }
  },
  {
    path: '/conference/announcement/detail',
    name: 'ConferenceAnnouncementDetail',
    meta: { title: '' },
    component: () =>
      import(/* webpackChunkName: "conference" */ '../views/meeting/announcement/detail.vue')
  },
  {
    path: '/conference/agenda',
    name: 'ConferenceAgenda',
    meta: { title: '' },
    component: () =>
      import(/* webpackChunkName: "conference" */ '../views/meeting/agenda/index.vue'),
    beforeEnter: to => {
      store.commit('cache/setKeep', 'Agenda')
    }
  },
  {
    path: '/conference/agenda/detail',
    name: 'ConferenceAgendaDetail',
    meta: { title: '' },
    component: () =>
      import(/* webpackChunkName: "conference" */ '../views/meeting/agenda/detail.vue')
  },
  {
    path: '/conference/news',
    name: 'ConferenceNew',
    meta: { title: '' },
    component: () => import(/* webpackChunkName: "conference" */ '../views/meeting/news/index.vue'),
    beforeEnter: to => {
      store.commit('cache/setKeep', 'News')
    }
  },
  {
    path: '/conference/news/detail',
    name: 'ConferenceNewsDetail',
    meta: { title: '' },
    component: () => import(/* webpackChunkName: "conference" */ '../views/meeting/news/detail.vue')
  },
  ...iraeRoutes
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

const getCode = () => {
  const params = {
    client_id: 'kyfd61434e7eb5fd4c',
    redirect_uri: 'https://ainsights.aimed.cn'
  }
  location.href = `${process.env.VUE_APP_AUTH_API_URL}/oauth2/authorize${queryParams(params)}`
}

router.beforeEach((to, from, next) => {
  const userId = myStorage.get('userId')
  if (to.meta.title === '清理') {
    next()
  } else {
    if (!userId) {
      const { code } = getURLParameters(location.search)
      if (code) {
        getYmtUserId({ code }).then(res => {
          if (res.code === 200) {
            myStorage.set('userId', res.data.user_id)
            next()
          } else {
            getCode()
          }
        })
      } else {
        getCode()
      }
    } else {
      next()
    }
  }
})

export default router
