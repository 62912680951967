const state = {
  keepLiveRoutes: []
}

const mutations = {
  setKeep(state, payload) {
    const setIndex = state.keepLiveRoutes.indexOf(payload)
    if (setIndex < 0) {
      state.keepLiveRoutes.push(payload)
    }
  },
  deleteKeep(state, payload) {
    const clearIndex = state.keepLiveRoutes.indexOf(payload)
    if (clearIndex >= 0) {
      state.keepLiveRoutes.splice(clearIndex, 1)
    }
  },
  clearKeepAll(state) {
    state.keepLiveRoutes = []
  }
}

export default {
  namespaced: true,
  state,
  mutations
}
