import { getMsdUserInfo } from '@/api/'
import { myStorage, uuid } from '@/utils'
import { createUser } from '@/api/buried'
import dayjs from 'dayjs'

// 注册
const registerHandle = () => {
  location.href = process.env.VUE_APP_INVITE_API_URL + '/auth/wechat/register'
}

const state = {
  userInfo: myStorage.get('userInfo') || {},
  loginTime: dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss'),
  pathId: uuid(12)
}

const mutations = {
  setUserInfo(state, payload) {
    state.userInfo = payload
  }
}

const actions = {
  async getUserInfo({ commit }, payload) {
    const res = await getMsdUserInfo({ token: payload })
    const {
      data: { id, role, hcpInfo, employeeInfo }
    } = res.data
    if (res.code === 200) {
      // 医生
      if (role === 'hcp') {
        if (
          hcpInfo.validateState === 'validated' ||
          hcpInfo.validateState === 'intern' ||
          hcpInfo.validateState === 'pending'
        ) {
          commit('setUserInfo', res.data.data)
          myStorage.set('userInfo', res.data.data)
          createUser({
            openid: id,
            nickname: hcpInfo.nickname,
            role
          })
        } else {
          registerHandle()
        }
      }
      // 员工
      if (role === 'employee') {
        commit('setUserInfo', res.data.data)
        myStorage.set('userInfo', res.data.data)
        createUser({
          openid: id,
          nickname: employeeInfo.email,
          role
        })
      }
      // 未知
      if (role === 'anonymous') {
        registerHandle()
      }
    } else {
      commit('setUserInfo', null)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
