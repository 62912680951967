export const iraeRoutes = [
  {
    path: '/irae',
    name: 'Irae',
    meta: { title: 'irAE诊疗手册' },
    component: () => import('../views/irae/home/')
  },
  {
    path: '/irae/baseline',
    name: 'IraeBaseline',
    meta: { title: '基线检查表' },
    component: () => import('../views/irae/baseline/')
  },
  {
    path: '/irae/toxicity',
    name: 'IraeToxicity',
    meta: { title: '毒性检查表' },
    component: () => import('../views/irae/toxicity/')
  },
  {
    path: '/irae/poison',
    name: 'IraePoison',
    meta: { title: '分级与推荐意见' },
    component: () => import('../views/irae/poison/')
  },
  {
    path: '/irae/poisonS',
    name: 'IraePoisonS',
    meta: { title: '' },
    component: () => import('../views/irae/poisonS/')
  },
  {
    path: '/irae/poisonG',
    name: 'IraePoisonG',
    meta: { title: '' },
    component: () => import('../views/irae/poisonG/')
  },
  {
    path: '/irae/detail',
    name: 'IraeDetail',
    meta: { title: '' },
    component: () => import('../views/irae/detail/')
  },
  {
    path: '/irae/guide',
    name: 'IraeGuide',
    meta: { title: '' },
    component: () => import('../views/irae/guide/')
  },
  {
    path: '/irae/guide/detail',
    name: 'IraeGuideDetail',
    meta: { title: '' },
    component: () => import('../views/irae/guideDetail/')
  }
]
