import Server from '@/server/'
const server = new Server({})

export const getClinicalNotice = params => {
  return server.request({
    url: '/tumor/cancer/ClinicalHomeNotice',
    method: 'get',
    params
  })
}

export const getTimeLine = params => {
  return server.request({
    url: '/tumor/milestone',
    method: 'get',
    params
  })
}

export const getReport = params => {
  return server.request({
    url: '/tumor/statistic',
    method: 'get',
    params
  })
}

export const getConference = params => {
  return server.request({
    url: '/tumor/monthly_meeting_agendas/'
  })
}

// 临床列表
export const getClinicalList = params => {
  return server.request({
    url: '/tumor/cancer',
    method: 'get',
    params
  })
}

// 临床研究
export const getClinicalDetail = params => {
  return server.request({
    url: '/tumor/cancer/clinicalDetail',
    method: 'get',
    params
  })
}
// 获取对比文献
export const getContrastDetail = params => {
  return server.request({
    url: '/tumor/cancer/contrastClinicalDetail',
    method: 'get',
    params
  })
}
// 临床搜索
export const getClinicalSearch = params => {
  return server.request({
    url: '/tumor/TumorSearch',
    method: 'get',
    params
  })
}

// 我的记录
export const getMyRecord = params => {
  return server.request({
    url: '/tumor/myself',
    method: 'get',
    params
  })
}

// 清空我的记录
export const deleteMyRecord = params => {
  return server.request({
    url: '/tumor/delete_myself',
    method: 'get',
    params
  })
}

// 获取医脉通用户id
export const getYmtUserId = data => {
  return server.request({
    url: '/tumor/med_live_auth/',
    method: 'post',
    data
  })
}

// 获取默沙东AccessToken
export const getMsdAccessToken = params => {
  return server.request({
    url: '/tumor/get_access_token/',
    method: 'get',
    params
  })
}

// 获取默沙东微邀请用户信息
export const getMsdUserInfo = params => {
  return server.request({
    url: '/tumor/get_user_info/',
    method: 'get',
    params,
    isLoading: false
  })
}
